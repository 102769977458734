import getScript from 'tt4/utils/getScript';

export default function (me2, flags, brand) {
    if (!me2.userrow) return;
    if (!me2.userrow.user) return;
    const { firstname, lastname, email } = me2.userrow.user[0];

    if (flags.test('chatbot_liveleader')) {
        window.chat = function chat() {
            (window.lvchtarr = window.lvchtarr || []).push(arguments);
        };
        window.chat('visitor', {
            name: `${firstname} ${lastname}`,
            email: email,
        });
        getScript('https://client.visma.chat/embed.js', {
            'data-chat-id': 'LIVuLoXDSxRBqjtaxkuBh92w',
        });
    }

    if (flags.test('chatbot_zenddesk')) {
        window.zESettings = {
            webWidget: {
                chat: {
                    departments: { enabled: [] },
                },
                offset: {
                    horizontal: '0px',
                    vertical: '0px',
                    mobile: {
                        horizontal: '50px',
                        vertical: '0px',
                    },
                },
            },
        };

        let prefillChat = () => {
            // zE is a global that comes with the script, so ESLint no likey
            // eslint-disable-next-line no-undef
            zE('webWidget', 'prefill', {
                name: {
                    value: `${firstname} ${lastname}`,
                    readOnly: true,
                },
                email: {
                    value: email,
                    readOnly: true,
                },
            });
        };

        let testEnvrionment = false;
        if (window?.location?.href?.indexOf('moveniumtest') > -1) {
            testEnvrionment = true;
        }

        if (brand.toLowerCase() === 'otta' && testEnvrionment) {
            getScript(
                'https://static.zdassets.com/ekr/snippet.js?key=bd447103-172d-4885-9690-a262e21789c8',
                { id: 'ze-snippet' }
            ).then(prefillChat);
            return;
        } else if (testEnvrionment) {
            getScript(
                'https://static.zdassets.com/ekr/snippet.js?key=d31a74f4-020f-4085-a728-b402b7edb3e0',
                { id: 'ze-snippet' }
            ).then(prefillChat);
            return;
        }

        getScript(
            'https://static.zdassets.com/ekr/snippet.js?key=0bf84e47-218a-4307-9557-f4f2b905cea4',
            { id: 'ze-snippet' }
        ).then(prefillChat);
    }
}
