/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/services/collector-service.coffee.old
// Generated by CoffeeScript 2.6.1
var collectorService,
    indexOf = [].indexOf;

import Ember from 'ember';

import collector from '../classes/collector';

import localization from '../classes/localization';

import config from '../config/environment';

import DS from 'ember-data';

import validator from '../classes/validations';

import { get } from '@ember/object';

import { sanitize } from 'dom-purify';

import { isArray } from '@ember/array';

import { getImageFromUrl, resizeImage } from 'tt4/utils/file-util';

import $ from 'jquery';

import { singularize, pluralize } from 'ember-inflector';

import { decamelize } from '@ember/string';

collectorService = Ember.Service.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    admintool: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    customerSettings: Ember.inject.service(),
    userSettings: Ember.inject.service(),
    capi: Ember.inject.service(),
    ttapiService: Ember.inject.service('ttapi'),
    flags: Ember.inject.service(),
    gui: Ember.inject.service(),
    addProducts: function (products, worktimegroup = null, is_addition_type = false) {
        var d, params, ret;
        //if products.get("firstObject") is "rfid"
        //company_name = @get('session.currentUser.company_info.company_name')
        //email_country = @get('session.currentUser.company_info.country')
        //email_content = @get('intl').t("general."+email_country+"_nfc_content")+"<br><br/>"+@get('intl').t("general."+email_country+"_nfc_customer_info")+":<br/>"+@get('session.currentUser.company_info.company_contact_person')+"<br>"+@get('session.currentUser.company_info.company_email')+"<br>"+@get('session.currentUser.company_info.company_phone');
        //email_header = @get('intl').t("general."+email_country+"_nfc_header")
        //promise = @ttapi
        //    url: "sendNfcEmail"
        //    data:
        //        content: email_content.replace("{customer}",company_name)
        //        header: email_header.string.replace("{customer}",company_name)
        //        country: email_country
        //    method: 'post'
        d = $.Deferred();
        params = {
            url: 'service/products',
            data: JSON.stringify({
                'service/product': {
                    products: products,
                },
                worktimegroup: worktimegroup,
                is_addition_type: is_addition_type,
            }),
            type: 'POST',
            contentType: 'application/json',
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
        };
        ret = this.ajax(params);
        ret.then(
            function (ret) {
                var j, len, prods, product;
                console.info('Adding products succeeded with content', ret);
                if (collector.products) {
                    prods = collector.products;
                } else {
                    prods = [];
                }
                for (j = 0, len = products.length; j < len; j++) {
                    product = products[j];
                    prods.push(product);
                }
                collector.products = prods;
                return d.resolve(ret);
            },
            function (e) {
                console.error('Adding products failed', e);
                return d.reject(e);
            },
        );
        return d;
    },
    addProduct: function (product, worktimegroup = null, is_addition_type = false) {
        return this.addProducts([product], worktimegroup, is_addition_type);
    },
    removeProduct: function (product, worktimegroup = null, is_addition_type = false) {
        var d, params, ret;
        d = $.Deferred();
        // when removing product without worktimegroup we add ?safe=true param so backend will prevent removing if it is on for some specific worktimegroup
        params = {
            url:
                'service/products/' +
                product +
                (worktimegroup ? '?worktimegroup=' + worktimegroup : '?safe=true') +
                (is_addition_type ? '&is_addition_type=true' : ''),
            type: 'DELETE',
            contentType: 'application/json',
            headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
            },
        };
        ret = this.ajax(params);
        ret.then(
            function (ret) {
                var j, len, old_prod, prods, prods_now;
                console.info('Removing product succeeded with content', ret);
                d.resolve(ret);
                prods = collector.products;
                prods_now = [];
                for (j = 0, len = prods.length; j < len; j++) {
                    old_prod = prods[j];
                    if (old_prod !== product) {
                        prods_now.push(old_prod);
                    }
                }
                return (collector.products = prods_now);
            },
            function (e) {
                return console.error('Removing product failed', e);
            },
        );
        return d;
    },
    setProducts: function (
        products,
        worktimegroup = null,
        force = false,
        is_addition_type = false,
    ) {
        var j, len, p, product, ret;
        console.log('setProducts: ', products, worktimegroup, force);
        ret = [];
        for (j = 0, len = products.length; j < len; j++) {
            product = products[j];
            if (product.substring(0, 1) === '!') {
                product = product.substring(1);
                if (this.productExists(product) || force) {
                    p = this.removeProduct(product.substring(9), worktimegroup, is_addition_type);
                    ret.push(p);
                }
            } else {
                if (!this.productExists(product) || force) {
                    console.log('add');
                    p = this.addProduct(product.substring(9), worktimegroup, is_addition_type);
                    ret.push(p);
                }
            }
        }
        return ret;
    },
    products: Em.computed(function () {
        return collector.products;
    }),
    fields: function (form) {
        if (!form) {
            return;
        }
        form = form.decamelize();
        form = form.underscore();
        if (collector.forms == null) {
            return null;
        }
        if (!(form in collector.forms)) {
            throw new Ember.Error("collectorService fields: form '" + form + "' not found");
        }
        return collector.forms[form].objects;
    },
    fieldArray: function (form, filter = false) {
        var field, fields, j, len, name, ret;
        fields = this.fields(form);
        ret = [];
        if (filter) {
            for (j = 0, len = filter.length; j < len; j++) {
                name = filter[j];
                if (fields[name] == null) {
                    continue;
                }
                field = fields[name];
                ret.push({
                    form: form,
                    name: name,
                    translated_name: this.getTranslationForColumn(form, name),
                    type: field.type,
                    options: field.options,
                    features: field.features,
                });
            }
        } else {
            for (name in fields) {
                field = fields[name];
                ret.push({
                    form: form,
                    name: name,
                    translated_name: this.getTranslationForColumn(form, name),
                    type: field.type,
                    options: field.options,
                    features: field.features,
                });
            }
        }
        return ret;
    },
    field: function (form, fieldName) {
        var field, ret;
        field = this.formField(form, fieldName);
        ret = {
            form: form,
            name: fieldName,
            translated_name: this.getTranslationForColumn(form, fieldName),
            type: field.type,
            options: field.options,
            features: field.features,
        };
        return ret;
    },
    formField: function (form, field) {
        return this.fields(form)[field];
    },
    uses_mongo: function (form) {
        if (!form) {
            return;
        }
        form = form.decamelize();
        form = form.underscore();
        if (collector.forms == null) {
            return null;
        }
        if (!(form in collector.forms)) {
            throw new Ember.Error("collectorService fields: form '" + form + "' not found");
        }
        return collector.forms[form].uses_mongo;
    },
    forms: function () {
        return collector.forms;
    },
    productExists: function (product) {
        var products, ref, split;
        products = collector.products;
        if (products) {
            if (product === 'products') {
                return true;
            }
            split = product.split('.');
            return (ref = split[1]), indexOf.call(products, ref) >= 0;
        }
        return false;
    },
    getProducts: function () {
        return collector.products;
    },
    moduleExists: function (module) {
        var modules, ref, split;
        modules = collector.modules;
        if (modules) {
            if (module === 'modules') {
                return true;
            }
            split = module.split('.');
            return (ref = split[1]), indexOf.call(modules, ref) >= 0;
        }
        return false;
    },
    formExists: function (name) {
        if (!collector.forms) {
            return;
        }
        return name in collector.forms;
    },
    fieldExists: function (form, name) {
        if (!this.formExists(form)) {
            return false;
        }
        return name in collector.forms[form].objects;
    },
    hasErrors: function (form) {
        if (!this.formExists(form)) {
            return false;
        }
        if (collector.forms[form].errors) {
            return true;
        }
    },
    feature: function (form, field, name) {
        var features, fields;
        if (!this.formExists(form)) {
            return null;
        }
        fields = this.fields(form);
        if (fields[field] == null) {
            return false;
        }
        if (fields[field].features == null) {
            return false;
        }
        features = fields[field].features;
        if ((!name) in features) {
            return false;
        }
        return features[name];
    },
    privilege: function (form, privilege) {
        if (!this.formExists(form)) {
            return null;
        }
        return collector.forms[form].privileges[privilege];
    },
    inverseNeeds: function (array) {
        var item, item_arr, j, l, len, len1, need, ret, split_need;
        ret = [];
        for (j = 0, len = array.length; j < len; j++) {
            need = array[j];
            split_need = need.split('||');
            item_arr = [];
            for (l = 0, len1 = split_need.length; l < len1; l++) {
                item = split_need[l];
                if (item.substring(0, 1) === '!') {
                    item = item.substring(1);
                } else {
                    item = '!' + item;
                }
                item_arr.push(item);
            }
            need = item_arr.join('||');
            ret.push(need);
        }
        return ret;
    },
    needsOfArray: function (array) {
        return Em.A(array).filter((item) => {
            if (!('needs' in item)) {
                return true;
            }
            return this.testNeeds(item.needs);
        });
    },
    testNeeds: function (need_array) {
        var all_ok, debug, debugText, inverse, j, l, len, len1, need, ok, split_need;
        if (!Em.isArray(need_array)) {
            Ember.assert('You need to provide an array to testNeeds function');
        }
        // Property for setting the main OK. This is false if atleast ONE need fails.
        all_ok = true;
        // If we don't have anything to test then just return all_ok
        if (Em.isEmpty(need_array)) {
            return all_ok;
        }
        debugText = '';
        debug = Em.A(need_array).find(function (item) {
            return item === 'debug';
        });
        // Test every need in the array
        for (j = 0, len = need_array.length; j < len; j++) {
            need = need_array[j];
            // We try to split needs because we might have OR (||) needs.
            // This works because split always returns array.
            split_need = typeof need === 'string' ? need.split('||') : [need];
            // We set the initial OK value to false before we start testing the need.
            // When we loop OR needs we can just check if OK is true and skip checking the rest.
            ok = false;
            if (need === 'debug') {
                continue;
            }
            for (l = 0, len1 = split_need.length; l < len1; l++) {
                need = split_need[l];
                // If ok is true we don't need to test splitted values anymore
                if (ok) {
                    continue;
                }
                if (typeof need === 'string') {
                    // See if we have inverse, ie. the string starts with "!" character
                    inverse = need.substring(0, 1) === '!';
                    if (inverse) {
                        // If we have inverse we need to cut the "!" from the start of the string
                        need = need.substring(1);
                    }
                }
                // Test the need
                ok = this.testNeedsOne(need);
                if (inverse) {
                    // If we have inverse (!) we switch the OK statement
                    ok = !ok;
                }
                if (debug) {
                    debugText += (inverse ? '!' : '') + need + '=' + ok + '||';
                }
            }
            if (debug) {
                debugText = debugText.substring(0, debugText.length - 2);
                if (!ok) {
                    debugText += ' => all_ok to false';
                }
                if (debug) {
                    debugText += '\n';
                }
            }
            if (!ok) {
                // Set the main OK to false if THIS need check fails.
                all_ok = false;
            }
        }
        if (debug) {
            debugText = debugText + '\nall_ok => ' + all_ok;
            console.debug(debugText);
        }
        return all_ok;
    },
    testNeedsOne: function (need) {
        var field, fieldandsetting, form, ok, ref, setting, split, userlevels, values;
        if (need === 'hide') {
            ok = false;
        } else if (typeof need === 'function') {
            ok = need();
        } else if (need === 'mobileView') {
            ok = window.innerWidth <= 768;
        } else if (need.substring(0, 9) === 'userlevel') {
            split = need.split('=');
            userlevels = this.addLoadProfilesForUserlevels(split[1].split(','));
            if (!this.get('session.currentUser')) {
                console.info(
                    "Needs cannot be tested for 'userlevel' because current user isn't loaded",
                );
                ok = indexOf.call(userlevels, '1') >= 0;
            } else {
                ok = ((ref = '' + this.getUserlevel()), indexOf.call(userlevels, ref) >= 0);
            }
        } else if (need === 'devMode') {
            ok = config.APP.devMode;
            // check if we are in devmode
        } else if (need === 'development') {
            ok = Ember.devModeOn === true;
        } else if (need.includes('formFeature')) {
            values = need.split('.');
            ok = this.testFormFeature(values[0], values[2]);
            // check if service has product
        } else if (need.substring(0, 8) === 'products') {
            ok = this.productExists(need);
            // check if service has module
        } else if (need.substring(0, 7) === 'modules') {
            ok = this.moduleExists(need);
            // modulecontrol check
        } else if (need.substring(0, 14) === 'modulecontrol.') {
            ok = this.testModuleControl(need.split('.')[1]);
            // modulecontrol check
        } else if (need.startsWith('flag.')) {
            ok = this.testFlag(need.split('.')[1]);
            // check for session.currentUser.field
        } else if (need.substring(0, 15) === 'userfieldcheck.') {
            ok = this.testCurrentUserField(need.split('.')[1]);
        } else if (need.substring(0, 14) === 'serviceCountry') {
            ok = this.testServiceCountry(need.split('@')[1]);
            // customer settings check
        } else if (need.substring(0, 15) === 'customersetting') {
            ok = this.get('customerSettings').checkSetting(need.split('.')[1]);
            // check form field privilege
        } else if (need.indexOf('@') !== -1 && need.indexOf('.') !== -1) {
            [form, fieldandsetting] = need.split('.');
            [field, setting] = fieldandsetting.split('@');
            ok = this.feature(form, field, setting);
            // check form privilege
        } else if (need.indexOf('@') !== -1) {
            split = need.split('@');
            split[0] = decamelize(split[0]);
            if (split[1] === 'errors') {
                ok = this.hasErrors(split[0]);
            } else {
                ok = this.privilege(split[0], split[1]);
            }
            // check form field existance
        } else if (need.indexOf('.') !== -1) {
            split = need.split('.');
            ok = this.fieldExists(split[0], split[1]);
        } else if (need === 'tt3') {
            return collector.get('tt3flag');
        } else if (need === 'userHasSaldos') {
            ok = this.get('userSettings.hasSaldos');
        } else if (need === 'passwordDisabled') {
            ok = this.session.currentUser?.disable_login;
        } else {
            ok = this.formExists(need);
        }
        return ok;
    },
    addLoadProfilesForUserlevels: function (userlevels) {
        var addThese, ret;
        addThese = [];
        ret = userlevels.map(function (userlevel) {
            var trimmed;
            if (userlevel.substring(userlevel.length - 1, userlevel.length) === '?') {
                trimmed = userlevel.substring(0, userlevel.length - 1);
                addThese = trimmed;
                return trimmed;
            } else {
                return userlevel;
            }
        });
        if (addThese.length > 0) {
            // this should be maybe loaded somehow from other forms also .. but this works for clockcard
            // which is the only thing using this userlevel=1? syntax for now
            if (
                this.feature('worktime', 'user', 'load_profile') ||
                this.privilege('worktime', 'limited_access')
            ) {
                ret.push('' + this.getUserlevel());
            }
        }
        return ret;
    },
    testCurrentUserField: function (value) {
        var session, user_field;
        if (this.getUserlevel() === 5) {
            return true;
        }
        if (!this.get('session.currentUser')) {
            Ember.assert(
                "Needs cannot be tested for 'userfieldcheck' because current user isn't loaded",
            );
            return false;
        }
        session = this.get('session');
        user_field = session.get('currentUser.' + value);
        if (user_field) {
            return true;
        } else if (!this.fieldExists('user', value)) {
            return true;
        }
    },
    testModuleControl: function (needed_module) {
        var module_controls;
        if (this.getUserlevel() === 5) {
            return true;
        }
        if (!this.fieldExists('user', 'module_controls')) {
            return true;
        }
        module_controls = this.get('session.currentUser.module_controls');
        if (!module_controls) {
            return false;
        }
        return indexOf.call(module_controls, needed_module) >= 0;
    },
    testFlag: function (name) {
        return this.get('flags').test(name);
    },
    testServiceCountry: function (country) {
        var company_country;
        company_country = this.get('session.currentUser.company_info.country');
        if (!company_country) {
            return false;
        }
        return country === company_country;
    },
    testFormFeature: function (form, feature) {
        var arr, formFeatures, invertNeeds;
        arr = [];
        invertNeeds = form.includes('!');
        form = invertNeeds ? form.substr(1) : form;
        if (!this.forms()[form]) {
            return false;
        }
        formFeatures = this.forms()[form].features;
        if (!formFeatures) {
            return false;
        }
        Object.values(formFeatures).forEach((values) => {
            if (!isArray(values)) {
                return arr.push(values);
            } else {
                return values.forEach((value) => {
                    return arr.push(value);
                });
            }
        });
        if (invertNeeds) {
            return !arr.includes(feature);
        } else {
            return arr.includes(feature);
        }
    },
    /*
  Get array of records.

  Returns promise and when resolved array of records.

  form: record type
  ids: array of row ids
  preLoad: if you can assume there isn't usually over 100 records this will reduce needed request count

  */
    getRecordArray: function (form, ids, preLoad = false) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var getRecords, store;
            store = this.get('store');
            getRecords = function () {
                var id, j, len, promise, promises, record, records;
                promises = [];
                records = [];
                for (j = 0, len = ids.length; j < len; j++) {
                    id = ids[j];
                    record = store.peekRecord(form, id);
                    if (record) {
                        records.push(record);
                    } else {
                        promise = store.findRecord(form, id);
                        promise.then(function (record) {
                            return records.push(record);
                        });
                    }
                    promises.push(promise);
                }
                return Ember.RSVP.Promise.all(promises).then(function () {
                    return resolve(records);
                });
            };
            // call for preload only if first object is not found from store
            // (prevents calling server every time this funciton called)
            if (preLoad && !store.peekRecord(form, ids[0])) {
                return store.findAll(form).then(() => {
                    return getRecords();
                });
            } else {
                return getRecords();
            }
        });
    },
    getProductByName: function (product) {
        var params, promise;
        if (this.get('product_list')) {
            return this.get('product_list').findBy('name', product);
        }
        if (this.get('product_list_fetcher')) {
            return this.get('product_list_fetcher');
        }
        params = {
            data: {
                country: this.get('session.currentUser.company_info.country'),
                lang: moment.locale(),
            },
        };
        promise = this.get('admintool')
            .get('products', params)
            .then((products) => {
                this.set('product_list', products);
                return console.log(products);
            });
        return this.set('product_list_fetcher', promise);
    },
    database_value: function (record, form, fields) {
        var field, j, len, val;
        val = '';
        if (!record) {
            return '';
        }
        for (j = 0, len = fields.length; j < len; j++) {
            field = fields[j];
            if (record.get(field)) {
                val += this._format_(record.get(field), form, field) + ' ';
            }
        }
        return val;
    },
    _format_: function (
        value,
        form,
        fieldName,
        format = 'html',
        record = null,
        isImageModal = false,
    ) {
        var field, first, second, values;
        if (!value && value !== false) {
            return '';
        }
        field = this.formField(form, fieldName);
        if (fieldName === 'row_info.created') {
            field = Ember.Object.create();
            Ember.set(field, 'type', 'row_info');
        }
        if (fieldName === 'row_info.modified') {
            field = Ember.Object.create();
            Ember.set(field, 'type', 'row_info');
        }
        Ember.set(field, 'ext', fieldName);
        if (fieldName === 'location' || fieldName === 'end_location') {
            Ember.set(field, 'type', 'gpslocation');
        }
        if (field.type === 'database' && Ember.typeOf(value) === 'number') {
            value = this.get('store').peekRecord(field.options.form, value);
        }
        if (field.type === 'date' && value.indexOf('_') !== -1) {
            values = value.split('_');
            first = this.format(values[0], field, null, record, format !== 'html', form);
            second = this.format(values[1], field, null, record, format !== 'html', form);
            return first + ' - ' + second;
        } else {
            return this.format(
                value,
                field,
                null,
                record,
                format === 'html' ? false : format,
                form,
                true,
                isImageModal,
            );
        }
    },
    format: function (
        value,
        field,
        controller,
        content = null,
        donotaddHTML = false,
        form = null,
        fuckTheseEmptyDivs = false,
        fuckTheseImageModals = false,
    ) {
        var allRecords,
            base,
            child_ext,
            classes,
            correctFields,
            d,
            dew_point,
            dist_txt,
            div,
            e,
            endHTML,
            exe_types,
            ext,
            fields,
            file,
            filethumb,
            fileurl,
            formattedHours,
            formattedStatus,
            gps,
            group_by_materialgroup,
            hours,
            html,
            humidity,
            image_types,
            item,
            j,
            json,
            l,
            large_image_url,
            len,
            len1,
            len2,
            len3,
            len4,
            len5,
            length,
            minus,
            minutes,
            ms,
            n,
            name,
            names,
            new_val,
            o,
            object,
            plus,
            q,
            r,
            ref,
            ref1,
            ref2,
            ref3,
            ref4,
            ref5,
            ref6,
            ref7,
            ret,
            return_str,
            right_ext,
            self,
            startHTML,
            statusIcon,
            storeData,
            string,
            target,
            target_field,
            temp,
            temperature,
            thisIsSignature,
            thumburl,
            tmp,
            tmpFields,
            translatedStatus,
            type,
            unit,
            url,
            val,
            value_arr,
            weather,
            windspeed;
        self = this;
        if (typeof field === 'string') {
            field = collector.getFormObject(field);
        }
        field = Em.Object.create(field);
        ext = field.get('ext') || field.get('name');
        type = field.get('type');
        if (ext === 'weather') {
            type = 'weather';
        }
        if (ext === 'location' || ext === 'end_location') {
            type = 'gpslocation';
        }
        if (ext === 'location_map') {
            type = 'gpslocationmap';
        }
        if (ext === 'km') {
            type = 'km';
        }
        if (this.unit(field)) {
            unit = ' ' + this.unit(field);
        } else {
            unit = '';
        }
        if (!['csv', 'excel', 'serverlessexcel'].includes(donotaddHTML)) {
            // Don't show tr measurement minus fields, they are formatted into plus rows
            if (ext.indexOf('_minus', ext.length - 6) !== -1) {
                return;
            }
            // Format measurements plus fields to also show minus value
            if (ext.indexOf('_plus', ext.length - 5) !== -1) {
                name = ext.substring(0, ext.length - 5);
                if (content) {
                    minus = content.get(name + '_minus');
                    plus = content.get(name + '_plus');
                } else if (controller) {
                    minus = controller.get('content.' + name + '_minus');
                    plus = controller.get('content.' + name + '_plus');
                }
                // If either one has value, we want to show it in report and force 0 if empty (bugfix EP-9036)
                if (plus != null || minus != null) {
                    plus = plus ? plus : '0';
                    minus = minus ? minus : '0';
                    val = plus + ' / ' + minus;
                } else {
                    val = '';
                }
                return val;
            }
        }
        if (
            !fuckTheseEmptyDivs &&
            !value &&
            field.get('type') !== 'checkbox' &&
            field.get('type') !== 'object' &&
            type !== 'gpslocation' &&
            value !== false &&
            value !== 0
        ) {
            if (donotaddHTML) {
                return '';
            }
            //put some empty spaces so we can edit empty cells
            div = document.createElement('div');
            div.innerHTML = '&nbsp;';
            div.setAttribute('style', 'width: 100%;cursor:text');
            return div;
        }
        switch (type) {
            case 'databasearray':
                // this is to get report databasearray-filters to show correctly when exporting
                if (Ember.typeOf(value) === 'string') {
                    tmpFields = this.fields(field.get('options.form'));
                    correctFields = tmpFields[field.get('options.fields.0')];
                    allRecords = this.get('store').peekAll(correctFields['options']['form']);
                    tmp = allRecords.findBy('id', value);
                    return tmp.get(correctFields['options']['fields'][0]);
                }
                // first only testing with worktime materials .. maybe could be used in other databasearrays also later
                if (
                    ext === 'material' &&
                    (donotaddHTML === false ||
                        donotaddHTML === 'pdf' ||
                        donotaddHTML === 'excel' ||
                        donotaddHTML === 'serverlessexcel')
                ) {
                    value_arr = [];
                    if (this.testNeeds(['materialgroup'])) {
                        group_by_materialgroup = {};
                        value.forEach((row) => {
                            if (Ember.typeOf(row.get) !== 'function') {
                                return;
                            }
                            if (group_by_materialgroup[row.get('materialgroup.name')] == null) {
                                group_by_materialgroup[row.get('materialgroup.name')] = [];
                            }
                            return group_by_materialgroup[row.get('materialgroup.name')].push(row);
                        });
                        Object.keys(group_by_materialgroup).forEach((materialgroup) => {
                            if (materialgroup !== 'undefined') {
                                if (donotaddHTML) {
                                    value_arr.push(materialgroup);
                                } else {
                                    value_arr.push('<b>' + materialgroup + '</b>');
                                }
                            }
                            return group_by_materialgroup[materialgroup].forEach((row) => {
                                val =
                                    row.get('type.name') +
                                    ': ' +
                                    row.get('amount') +
                                    ' ' +
                                    (row.get('type.unit') || '');
                                if (
                                    donotaddHTML === 'pdf' ||
                                    donotaddHTML === 'excel' ||
                                    donotaddHTML === 'serverlessexcel'
                                ) {
                                    return value_arr.push(val);
                                } else {
                                    return value_arr.push('&nbsp;&nbsp;&nbsp;' + val);
                                }
                            });
                        });
                    } else {
                        value.forEach((row) => {
                            if (row.get == null) {
                                return;
                            }
                            if (
                                row.get('type.name') &&
                                Ember.typeOf(row.get('amount')) !== 'undefined'
                            ) {
                                return value_arr.push(
                                    row.get('type.name') +
                                        ': ' +
                                        row.get('amount') +
                                        ' ' +
                                        (row.get('type.unit') || ''),
                                );
                            }
                        });
                    }
                    if (donotaddHTML) {
                        return value_arr.join(' / ');
                    } else {
                        return new Ember.String.htmlSafe(
                            '<span class="small text-nowrap">' + value_arr.join('<br>') + '</span>',
                        );
                    }
                } else if (
                    ext === 'multitask' &&
                    (donotaddHTML === false ||
                        donotaddHTML === 'pdf' ||
                        donotaddHTML === 'excel' ||
                        donotaddHTML === 'serverlessexcel')
                ) {
                    value_arr = [];
                    value.forEach((row) => {
                        var formattedHours, hoursWithClock;
                        if (row.get == null) {
                            return;
                        }
                        if (
                            row.get('taskname.name') &&
                            Ember.typeOf(row.get('hours')) !== 'undefined'
                        ) {
                            formattedHours = localization.formatHours(row.get('hours'));
                            hoursWithClock =
                                '<span class="move move-Time"></span> ' + formattedHours;

                            // if subtask is added to the task, add it to the string also,
                            // so it can be seen in the report/pdf/csv
                            const subTask = row.get('subtaskname.name') ?? '';

                            return value_arr.push(
                                row.get('taskname.name') +
                                    ': ' +
                                    (donotaddHTML ? formattedHours : hoursWithClock) +
                                    ' ' + subTask,
                            );
                        }
                    });
                    if (donotaddHTML) {
                        return value_arr.join(' / ');
                    } else {
                        return new Ember.String.htmlSafe(
                            '<span class="small text-nowrap">' + value_arr.join('<br>') + '</span>',
                        );
                    }
                } else {
                    return value.get('length');
                }
                break;
            case 'hours':
                // this is some ugly code!!!!
                if (
                    donotaddHTML === 'pdf' ||
                    donotaddHTML === 'sumrow' ||
                    donotaddHTML === 'calendar' ||
                    donotaddHTML === 'excel' ||
                    donotaddHTML === 'serverlessexcel' ||
                    donotaddHTML === 'salarycategory'
                ) {
                    return localization.formatHours(value, donotaddHTML);
                } else if (donotaddHTML) {
                    return value;
                } else {
                    if (!isNaN(value)) {
                        if (Ember.typeOf(value) === 'string') {
                            value = parseFloat(value);
                        }
                        value = value.toFixed(2);
                    }
                    formattedHours = localization.formatHours(value);
                    if (field.features.custom === 'format_also_days') {
                        formattedHours = localization.formatDaysAndHours(value);
                    }
                    return new Ember.String.htmlSafe(
                        '<span class="move move-Time"></span> ' + formattedHours,
                    );
                }
                break;
            case 'minutes':
                if (value > 60) {
                    hours = value / 60;
                    minutes = Math.round((hours - Math.floor(hours)) * 60);
                    return Math.floor(hours) + 'h:' + (minutes < 10 ? '0' : '') + minutes + 'min';
                }
                return value + 'min';
            case 'status':
                translatedStatus = this.get('intl').exists('status.' + value + '_text');
                if (translatedStatus) {
                    formattedStatus = this.get('intl').t('status.' + value + '_text');
                }
                if (donotaddHTML) {
                    if (translatedStatus) {
                        return formattedStatus;
                    } else {
                        return value;
                    }
                } else {
                    if (translatedStatus) {
                        if (value === 'error_in' || value === 'error_out') {
                            formattedStatus = this.get('intl').t('status.system_edited_text');
                        }
                        statusIcon = (function () {
                            switch (value) {
                                case 'open':
                                    return 'Circle';
                                case 'approved':
                                case 'ok':
                                    return 'CheckedCircle';
                                case 'closed':
                                case 'invoiced':
                                    return 'DoubleTick';
                                case 'saved':
                                    return 'SaveDisk';
                                case 'fixed':
                                    return 'Maintenance';
                                case 'new':
                                    return 'Error';
                                case 'not_verified':
                                case 'blocked':
                                    return 'DoNotDisturb';
                                case 'error_in':
                                case 'error_out':
                                case 'system_edited':
                                    return 'SystemReport';
                                case 'rejected':
                                    return 'Rejected';
                                default:
                                    return false;
                            }
                        })();
                        if (statusIcon) {
                            return new Ember.String.htmlSafe(
                                '<span class="' +
                                    value +
                                    ' report-status-icon" title="' +
                                    formattedStatus +
                                    '"><span class="move move-' +
                                    statusIcon +
                                    '"></span></span>',
                            );
                        } else {
                            string = this.shortFormat(formattedStatus);
                            return new Ember.String.htmlSafe(
                                '<span class="report-status-icon ' +
                                    value +
                                    '" title="' +
                                    formattedStatus +
                                    '">' +
                                    string.toUpperCase() +
                                    '</span>',
                            );
                        }
                    } else {
                        string = this.shortFormat(value);
                        return new Ember.String.htmlSafe(
                            '<span class="report-status-icon own-status" title="' +
                                value +
                                '">' +
                                string.toUpperCase() +
                                '</span>',
                        );
                    }
                }
                break;
            case 'dropdown_userlevel':
                // own userrole
                if (parseInt(value) > 9) {
                    field.get('options').forEach(function (option) {
                        if (option.indexOf(value) === 0) {
                            return (name = option.substring(2));
                        }
                    });
                    return name;
                } else {
                    if (!content && !form) {
                        if (controller.get('content.length') > 1) {
                            content = controller.get('content.firstObject');
                        } else {
                            content = controller.get('content');
                        }
                        form = content.get('constructor.modelName');
                    } else if (content) {
                        form = content.get('constructor.modelName');
                    }
                    return this.get('intl').t(form + '.' + ext + '.' + value);
                }
                break;
            case 'dropdown_options':
            case 'dropdown_values':
            case 'dropdown_language':
                if (Ember.isArray(value)) {
                    return this.formatDropdownSums(value, field, form, donotaddHTML);
                }
                if (form) {
                    return this.getDropdownValue(null, value, field, form);
                }
                if (!content) {
                    if (controller.get('content.length') > 1) {
                        content = controller.get('content.firstObject');
                    } else {
                        content = controller.get('content');
                    }
                }
                return this.getDropdownValue(content, value, field);
            case 'dropdown_country':
                return value.toUpperCase();
            case 'timerlink':
                if (!value) {
                    return value;
                }
                if (value.get == null) {
                    return value;
                }
                if (
                    Ember.isEmpty(Ember.get(value, 'endtime')) &&
                    Ember.isEmpty(Ember.get(value, 'starttime'))
                ) {
                    return;
                }
                if (Ember.isEmpty(Ember.get(value, 'endtime'))) {
                    return this.get('intl').t('general.timerlink.started');
                }
                ms = moment(Ember.get(value, 'endtime')).diff(
                    moment(Ember.get(value, 'starttime')),
                );
                d = moment.duration(ms);
                hours = Math.floor(d.asHours());
                if (hours < 10) {
                    hours = '0' + hours;
                }
                return hours + moment.utc(ms).format(':mm:ss');
            case 'database':
                val = '';
                if (!value) {
                    return value;
                }
                if (value.get == null) {
                    return value;
                }
                fields = this.fields(field.get('options.form'));
                ref = field.get('options.fields');
                for (j = 0, len = ref.length; j < len; j++) {
                    field = ref[j];
                    if (fields[field]) {
                        type = fields[field].type;
                    } else {
                        type = null;
                    }
                    if (type === 'hdfile') {
                        continue;
                    }
                    if (value.get(field)) {
                        temp = value.get(field);
                        if (type === 'date') {
                            temp = moment(temp).format('L');
                        }
                        val += temp + ' ';
                    }
                }
                return val;
            case 'month_dropdown':
                if (value === '0000-00-00') {
                    return '';
                }
                return moment(value).format('MMMM YYYY');
            case 'date':
                if (Ember.typeOf(value) === 'string' && value.substring(0, 6) === 'count:') {
                    return value.substring(6);
                }
                if (Ember.typeOf(value) !== 'string') {
                    return value;
                }
                if (value === '0000-00-00') {
                    return '';
                }
                if (donotaddHTML === 'csv' || donotaddHTML === 'serverlessexcel') {
                    return moment(value).format('L');
                }
                return moment(value).format('dd') + ', ' + moment(value).format('L');
            case 'datetime':
                if (Ember.typeOf(value) !== 'string') {
                    return value;
                }
                return moment(value).format('L LT');
            case 'checkbox':
                if (donotaddHTML === 'salarycategory') {
                    return value + ' ' + this.get('intl').t('general.pieces');
                }
                if (Ember.typeOf(value) === 'string' && value.includes(':')) {
                    return this.formatCheckboxSums(value);
                }
                ret = function () {
                    switch (value) {
                        case '1':
                        case true:
                        case 'on':
                            return this.get('intl').t('general.yes');
                        default:
                            return this.get('intl').t('general.no');
                    }
                }.call(this);
                return ret;
            case 'hdfile':
            case 'fileupload':
                image_types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/pjpeg'];
                exe_types = [
                    'application/octet-stream',
                    'application/x-msdownload',
                    'application/exe',
                    'application/x-exe',
                    'application/dos-exe',
                    'vms/exe',
                    'application/x-winexe',
                    'application/msdos-windows',
                    'application/x-msdos-program',
                ];
                if (value.length >= 1) {
                    return_str = '';
                    for (l = 0, len1 = value.length; l < len1; l++) {
                        file = value[l];
                        if (((ref1 = file.type), indexOf.call(exe_types, ref1) >= 0)) {
                            return file.name;
                        }
                        if (((ref2 = file.type), indexOf.call(image_types, ref2) >= 0)) {
                            if (donotaddHTML) {
                                return '';
                            }
                            fileurl = file.s3url || file.url;
                            filethumb = file.s3thumb || file.thumb;
                            if (file.name === 'signature.png') {
                                file.thumb = fileurl;
                            }
                            if (file.name === 'naama.png') {
                                file.thumb = fileurl;
                            }
                            if (filethumb == null) {
                                return null;
                            }
                            url = this.makeFileUrl(collector.api_url() + '/', filethumb);
                            large_image_url = this.makeFileUrl(collector.api_url() + '/', fileurl);
                            // super cool way to render signatures differently .. :+1:
                            thisIsSignature = false;
                            if (field.ext) {
                                thisIsSignature = field.ext.search('sign') !== -1;
                            }
                            classes = 'img-thumb' + (thisIsSignature ? ' img-thumb-sign' : '');
                            if (fuckTheseImageModals) {
                                return {
                                    isImage: true,
                                    src: fileurl,
                                    classes,
                                };
                            }
                            // This is bit ugly to inject router here but what can you do
                            base = this.getModalUrl('modal=showImage,' + fileurl);
                            startHTML =
                                '<a class="hidden-print" href="' +
                                base +
                                '"><div class="' +
                                classes +
                                '" style="background-image:url(\'';
                            endHTML =
                                '\');"></div></a><div class="visible-print ' +
                                classes +
                                '" style="content:url(\'' +
                                url +
                                '\');"></div>';
                            return_str += ' ' + startHTML + url + endHTML;
                        } else if (file.url || file.s3url) {
                            if (donotaddHTML) {
                                return_str += file.name + ' ';
                            } else {
                                url = this.makeFileUrl(
                                    collector.api_url() + '/',
                                    file.url ? file.url : file.s3url,
                                );
                                target = this.get('gui').isIOS
                                    ? ' target="_system"'
                                    : ' target="_blank"';
                                return_str +=
                                    ' ' +
                                    '<a href="' +
                                    url +
                                    '" ' +
                                    target +
                                    '>' +
                                    file.name +
                                    '</a>';
                            }
                        }
                    }
                    if (donotaddHTML) {
                        return return_str;
                    }
                    return new Ember.String.htmlSafe(return_str);
                } else {
                    if (((ref3 = value.type), indexOf.call(image_types, ref3) >= 0)) {
                        if (donotaddHTML) {
                            return '';
                        }
                        if (value.thumb == null) {
                            return null;
                        }
                        url = this.makeFileUrl(collector.api_url() + '/', value.thumb);
                        large_image_url = this.makeFileUrl(collector.api_url() + '/', value.url);
                        // super cool way to render signatures differently .. :+1:
                        thisIsSignature = field.ext.search('sign') !== -1;
                        classes = 'img-thumb' + (thisIsSignature ? ' img-thumb-sign' : '');
                        // This is bit ugly to inject router here but what can you do
                        base = this.getModalUrl('modal=showImage,' + value.url);
                        startHTML =
                            '<a class="hidden-print" href="' +
                            base +
                            '"><div class="' +
                            classes +
                            '" style="background-image:url(\'';
                        endHTML =
                            '\');"></div></a><div class="visible-print ' +
                            classes +
                            '" style="content:url(\'' +
                            url +
                            '\');"></div>';
                        return new Ember.String.htmlSafe(startHTML + url + endHTML);
                    } else if (value.url) {
                        if (donotaddHTML) {
                            return file.name + ' ';
                        }
                        url = this.makeFileUrl(collector.api_url() + '/', value.url);
                        return new Ember.String.htmlSafe(
                            '<a href="' + url + '" target="_blank ">' + value.name + '</a>',
                        );
                    } else {
                        return null;
                    }
                }
                break;
            case 'signature':
                if (donotaddHTML) {
                    return '';
                }
                image_types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/pjpeg'];
                if (value.length > 0 && typeof value !== 'string') {
                    value = value[0];
                }
                if (((ref4 = value.type), indexOf.call(image_types, ref4) >= 0)) {
                    url = value.s3url ? value.s3url : collector.api_url() + '/' + value.url;
                    thumburl = value.s3thumb ? value.s3thumb : url;
                    classes = 'img-thumb img-thumb-sign';
                    startHTML =
                        '<a href="' +
                        url +
                        '" target="_blank "><div class="' +
                        classes +
                        '" style="background-image:url(\'';
                    endHTML =
                        '\');"></div><style>@media print{div.img-thumb::after{content:url("' +
                        thumburl +
                        '");}}</style></a>';
                    return new Ember.String.htmlSafe(startHTML + thumburl + endHTML);
                }
                if (typeof value === 'string') {
                    //for mongo, signatures are stored directly as a base64 string
                    type = value.substr(0, value.indexOf(';')).substr(5); //extract image type from "data:image/png;base64here"
                    if (indexOf.call(image_types, type) >= 0) {
                        classes = 'img-thumb img-thumb-sign';
                        html =
                            '<div class="' +
                            classes +
                            '" style="background-image:url(\'' +
                            value +
                            '\')"</div></a>';
                        return new Ember.String.htmlSafe(html);
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
                break;
            case 'object':
                if (content != null) {
                    right_ext = ext.split('_');
                    object = content[right_ext[0]];
                    right_ext.shift();
                    child_ext = right_ext.join('_');
                    if (object != null) {
                        target_field = {
                            type: field.get('target_type'),
                            ext: child_ext,
                            features: {},
                        };
                        return this.format(
                            object[child_ext],
                            target_field,
                            null,
                            Em.Object.create(object),
                            donotaddHTML,
                        );
                    }
                }
                return '';
            case 'multiselect':
                if (Ember.typeOf(value) === 'number') {
                    value = this.get('store').peekRecord(field.get('options.form'), value);
                    val = '';
                    if (!value) {
                        return value;
                    }
                    if (value.get == null) {
                        return value;
                    }
                    fields = this.fields(field.get('options.form'));
                    ref5 = field.get('options.fields');
                    for (n = 0, len2 = ref5.length; n < len2; n++) {
                        field = ref5[n];
                        if (value.get(field)) {
                            val += value.get(field);
                        }
                    }
                    return val;
                }
                if (ext.includes('_notices') && value.length > 0) {
                    length = new Set(value).size;
                    return length;
                }
                if (Ember.typeOf(value) === 'array') {
                    storeData =
                        (ref6 = this.get('store').peekAll(field.get('options.form'))) != null
                            ? ref6.map((rec) => {
                                  return parseInt(rec.id);
                              })
                            : void 0;
                    value = storeData.length
                        ? value.filter((v) => {
                              return storeData.includes(v);
                          })
                        : value;
                }
                if (value.length > 0) {
                    return value.length + ' ' + this.get('intl').t('report.items_checked');
                }
                break;
            case 'password':
                if (value === 'secured') {
                    if (donotaddHTML) {
                        return '';
                    }
                    return new Ember.String.htmlSafe(
                        '<span class="label-m label-m-approved">' +
                            this.get('intl').t('status.secured') +
                            '</span>',
                    );
                } else {
                    if (donotaddHTML) {
                        return value;
                    }
                    return new Ember.String.htmlSafe(
                        '<span class="label-m label-m-open lowercase">' + value + '</span>',
                    );
                }
                break;
            case 'embedded':
                new_val = '';
                ref7 = field.get('options');
                for (o = 0, len3 = ref7.length; o < len3; o++) {
                    field = ref7[o];
                    if (value[field]) {
                        new_val += value[field] + ', ';
                    }
                }
                return (value = new_val.slice(0, -2));
            case 'checkboxgroup':
                names = [];
                self = this;
                value.forEach((sub_value) => {
                    var addthis;
                    if (field.options[sub_value] == null) {
                        return;
                    }
                    if (field.options[sub_value].substring(0, 1) !== '$') {
                        addthis = field.options[sub_value];
                    } else {
                        ext = field.ext ? field.ext : field.external_type;
                        addthis = self.get('intl').t('orientations.' + ext + '.' + sub_value); // need to find better solution for this (works for orientations only now)
                    }
                    if (
                        addthis === field.options[sub_value] &&
                        this.get('intl').exists(field.options[sub_value])
                    ) {
                        addthis = this.get('intl').t(field.options[sub_value]);
                    }
                    return names.push(donotaddHTML ? addthis : this.minifyLong(addthis));
                });
                if (donotaddHTML) {
                    return names.join(', ');
                } else {
                    return new Ember.String.htmlSafe(names.join('<br>'));
                }
                break;
            case 'gpslocation':
                if (value) {
                    try {
                        gps = JSON.parse(value);
                    } catch (error1) {
                        e = error1;
                        return '';
                    }
                    if (gps.distance) {
                        if (gps.distance > 1000) {
                            dist_txt = Math.round(gps.distance / 1000) + 'km';
                        } else {
                            dist_txt = Math.round(gps.distance) + 'm';
                        }
                        if (donotaddHTML) {
                            return dist_txt;
                        }
                        return new Ember.String.htmlSafe(
                            '<span class="label-m label-m-approved" title="Lat: ' +
                                gps.latitude +
                                ', Long: ' +
                                gps.longitude +
                                ', Dist ' +
                                dist_txt +
                                '">' +
                                dist_txt +
                                '</span>',
                        );
                    } else {
                        if (donotaddHTML) {
                            return this.get('intl').t('general.yes');
                        }
                        return new Ember.String.htmlSafe(
                            '<span class="label-m label-m-approved" title="Lat: ' +
                                gps.latitude +
                                ', Long: ' +
                                gps.longitude +
                                '"><span class="glyphicon glyphicon-ok"></span></span>',
                        );
                    }
                } else {
                    if (donotaddHTML) {
                        return this.get('intl').t('general.no');
                    }
                    return new Ember.String.htmlSafe(
                        '<span class="label-m label-m-open" title="' +
                            this.get('intl').t('field.gps.no_value') +
                            '"><span class="glyphicon glyphicon-remove"></span></span>',
                    );
                }
                break;
            case 'gpslocationmap':
                if (value) {
                    let gps = null;
                    try {
                        gps = JSON.parse(value);
                    } catch (e) {
                        return 'broken coordinates';
                    }

                    if (donotaddHTML) {
                        return this.get('intl').t('general.yes');
                    }
                    return new Ember.String.htmlSafe(
                        '<span class="label-m label-m-approved" title="Lat: ' +
                            gps.latitude +
                            ', Long: ' +
                            gps.longitude +
                            '"><span class="glyphicon glyphicon-ok"></span></span>',
                    );
                }
                break;
            case 'timeclean_imei':
                if (!value) {
                    return '';
                }
                try {
                    value = JSON.parse(value);
                } catch (error1) {
                    e = error1;
                    value = [value];
                }
                if (donotaddHTML) {
                    string = '';
                    for (q = 0, len4 = value.length; q < len4; q++) {
                        item = value[q];
                        string += item + ', ';
                    }
                    return string.slice(0, -2);
                } else {
                    string = '';
                    for (r = 0, len5 = value.length; r < len5; r++) {
                        item = value[r];
                        string += '<code>' + item + '</code><br>';
                    }
                    return new Ember.String.htmlSafe(string.slice(0, -4));
                }
                break;
            case 'password':
                if (value === 'secured') {
                    return new Ember.String.htmlSafe(
                        '<span class="label-m label-m-approved">' + value.toUpperCase() + '</span>',
                    );
                } else {
                    return new Ember.String.htmlSafe(
                        '<span class="label-m label-m-open">' + value + '</span>',
                    );
                }
                break;
            case 'weather':
                if (value === 'null') {
                    return '';
                }
                if (Em.isBlank(value)) {
                    return '';
                }
                if (value && value !== '') {
                    if (Ember.typeOf(value) === 'string') {
                        if (value.slice(0, 1) === '"' && value.slice(-1) === '"') {
                            value = value.slice(1, -1);
                        }
                        try {
                            json = JSON.parse(value);
                        } catch (error1) {
                            e = error1;
                            return '';
                        }
                    } else {
                        json = value;
                    }
                    if (donotaddHTML) {
                        temperature = json.temperature ? json.temperature : '-';
                        windspeed = json.windspeed ? json.windspeed : '-';
                        humidity = json.humidity ? json.humidity : '-';
                        dew_point = json.dew_point ? json.dew_point : '-';
                        weather = temperature + ' °C, ' + windspeed + ' m/s ' + humidity + ' % ';
                        weather +=
                            this.get('intl').t('worksitediary.abbr_rel_hum') +
                            ', ' +
                            this.get('intl').t('worksitediary.abbr_dew_point') +
                            ': ' +
                            dew_point +
                            ' °C';
                        return weather;
                    } else {
                        string =
                            "<span class='weather-icon move move-" + json.weather + "'></span> ";
                        if (json.temperature) {
                            string += json.temperature + '&deg;C, ';
                        } else {
                            string += '- &deg;C, ';
                        }
                        if (json.windspeed) {
                            string += json.windspeed + ' m/s, ';
                        } else {
                            string += '- m/s, ';
                        }
                        if (json.humidity) {
                            string +=
                                json.humidity +
                                '% ' +
                                this.get('intl').t('worksitediary.abbr_rel_hum') +
                                ', ';
                        } else {
                            string +=
                                '- % ' + this.get('intl').t('worksitediary.abbr_rel_hum') + ', ';
                        }
                        string += this.get('intl').t('worksitediary.abbr_dew_point') + ': ';
                        if (json.dew_point) {
                            string += json.dew_point;
                        } else {
                            string += '- &deg;C';
                        }
                        return new Ember.String.htmlSafe(string);
                    }
                } else {
                    return '';
                }
                break;
            case 'radio':
                if (!value) {
                    return value;
                }
                if (value.substring(0, 1) === '$') {
                    return this.get('intl').t(form + '.option.' + value.substring(1));
                } else {
                    return this.get('intl').t(form + '.' + ext + '.' + value);
                }
                break;
            case 'row_info':
                if ((ext = 'row_info.created' && donotaddHTML !== 'serverlessexcel')) {
                    return moment(value, 'L LT').format('L LT');
                } else {
                    return value;
                }
                break;
            case 'textarea':
                if (donotaddHTML === 'serverlessexcel') {
                    return value.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
                } else if (donotaddHTML) {
                    return sanitize(value);
                } else {
                    return Ember.String.htmlSafe(
                        "<span style='white-space: pre-line;'>" + sanitize(value) + '</span>',
                    );
                }
                break;
            case 'price':
                if (!value) {
                    return value;
                }
                return Math.round((value + 0.00001) * 100) / 100;
            case 'km':
                if (!['csv', 'excel', 'serverlessexcel'].includes(donotaddHTML)) {
                    return value + ' km';
                }
                return value;
            default:
                if (controller && controller.get('parentController')) {
                    value = controller.get('parentController').formatter(ext, value + unit);
                }
                if (donotaddHTML === 'pdf') {
                    return '' + value;
                }
                return value;
        }
    },
    formatDropdownSums: function (values, field, form, donotaddHTML) {
        var formattedSums, pdfSums;
        formattedSums = '';
        pdfSums = '';
        Object.entries(values).forEach((data) => {
            var option, translatedValue;
            if (this.get('intl').exists(form + '.' + field.ext + '.' + data[0])) {
                translatedValue = this.get('intl').t(form + '.' + field.ext + '.' + data[0]);
            } else if (this.get('intl').exists(form + '.' + field.external_type + '.' + data[0])) {
                translatedValue = this.get('intl').t(
                    form + '.' + field.external_type + '.' + data[0],
                );
            } else {
                option = field.options.find((v) => {
                    return v.id === data[0];
                });
                translatedValue = option && Ember.typeOf(option) === 'object' ? option.value : null;
            }
            if (translatedValue) {
                formattedSums += '<div>' + translatedValue + ': ' + data[1] + '</div>';
                return (pdfSums += translatedValue + ': ' + data[1] + '\n');
            }
        });
        if (donotaddHTML === 'sumrow') {
            return pdfSums;
        }
        return Ember.String.htmlSafe(formattedSums);
    },
    makeFileUrl: function (url, str) {
        if (str.startsWith('http')) {
            return str;
        } else {
            return url + str;
        }
    },
    formatCheckboxSums: function (value) {
        var onoff, ret;
        onoff = value.split(',');
        ret = [];
        onoff.forEach((item) => {
            var v;
            v = item.split(':');
            // If just on or off and value is 1 don't show amounts as capi someone returs these as false/true or on:1 / off:1
            // trying to be cohesive like this
            if (onoff.length === 1 && v[1] === '1') {
                return ret.push(
                    v[0] === 'on'
                        ? this.get('intl').t('general.yes')
                        : this.get('intl').t('general.no'),
                );
            } else {
                return ret.push(
                    v[0] === 'on'
                        ? this.get('intl').t('general.yes') + ': ' + v[1]
                        : this.get('intl').t('general.no') + ': ' + v[1],
                );
            }
        });
        // Reverse so we have yes first as in report sums
        return ret.reverse().join(' / ');
    },
    minifyLong: function (str, length = 20) {
        if (str.string != null) {
            str = str.string;
        }
        if (str.length <= length) {
            return str;
        }
        return '<span title="' + str + '">' + str.substring(0, length) + '...</span>';
    },
    getModalUrl: function (url) {
        var base, split;
        base = this.get('routing.router.location.location.href');
        split = base.split('?');
        if (split[1] != null && split[1].substr(0, 6) === 'modal=') {
            delete split[1];
        }
        url = encodeURIComponent(url);
        url = url.replace('%3D', '=');
        if (url.startsWith('http')) {
            console.log('here');
            return url;
        }
        return split[0] + '?' + (split[1] != null ? split[1] + '&' + url : url);
    },
    getDropdownValue: function (content, value, field, form = null) {
        var ext, option, options, ret;
        ext = Ember.get(field, 'ext');
        if (Ember.get(field, 'name')) {
            options = Ember.get(field, 'options');
            if (form) {
                ret = this.parseDropdownSums(form, field, value);
                if (ret) {
                    return ret;
                }
            }
            option = options.findBy('id', value);
            if (!option) {
                return value;
            }
            return option.value;
        } else if (form) {
            ret = this.parseDropdownSums(form, field, value);
            if (!ret) {
                options = Ember.get(field, 'options');
                option = options.findBy('id', value);
                if (!option) {
                    return value;
                }
                return option.value;
            }
            return ret;
        } else {
            return this.get('intl').t(
                content.get('constructor.modelName') + '.' + ext + '.' + value,
            );
        }
    },
    parseDropdownSums: function (form, field, value) {
        var arr, ext, index, is_summing, j, len, m, option, options, str, summing_object, temp_arr;
        ext = Ember.get(field, 'ext') ? Ember.get(field, 'ext') : Ember.get(field, 'external_type');
        // test for summing
        is_summing = true;
        summing_object = {};
        if (Ember.typeOf(value) === 'string') {
            arr = value.split(',');
            for (j = 0, len = arr.length; j < len; j++) {
                str = arr[j];
                m = /(.+):(\d*)/i.exec(str);
                if (m === null) {
                    is_summing = false;
                } else {
                    summing_object[m[1]] = m[2];
                }
            }
        }
        if (is_summing) {
            options = Ember.get(field, 'options');
            temp_arr = [];
            for (index in summing_object) {
                option = options.findBy('id', index);
                if (this.get('intl').exists(form + '.' + ext + '.' + index)) {
                    temp_arr.push(
                        this.get('intl').t(form + '.' + ext + '.' + index) +
                            ':' +
                            summing_object[index],
                    );
                } else if (option) {
                    temp_arr.push(option.value + ':' + summing_object[index]);
                } else {
                    temp_arr.push(index + ':' + summing_object[index]);
                }
            }
            return temp_arr.join(', ');
        } else if (this.get('intl').exists(form + '.' + ext + '.' + value)) {
            return this.get('intl').t(form + '.' + ext + '.' + value);
        } else {
            return false;
        }
    },
    format_export: function (value) {
        if (value != null && /<[a-z][\s\S]*>|{[\s\S]*}/i.test(value.string)) {
            value = '';
        }
        if (
            value instanceof HTMLElement ||
            value == null ||
            /<[a-z][\s\S]*>|{[\s\S]*}/i.test(value)
        ) {
            value = '';
        }
        return value.toString();
    },
    unit: function (field) {
        switch (field.get('type')) {
            case 'minutes':
                return 'min';
            case 'price':
                return '€';
            case 'big_price':
                return '€';
            default:
                return field.get('options');
        }
    },
    isMandatory: function (field, model) {
        if (field && typeof field.features.mandatory === 'object') {
            return validator._is_visible(model, field.features.mandatory);
        } else if (field.features.mandatory) {
            return true;
        }
        return false;
    },
    isHidden: function (field, model) {
        if (field && typeof field.features.hide === 'object') {
            return !validator._is_visible(model, field.features.hide);
        } else if (field.features.hide) {
            return true;
        }
        return false;
    },
    getFieldName: function (form, field) {
        var name;
        if (field.get('translation')) {
            return this.get('intl').t(field.get('translation'));
        }
        form = form.underscore();
        if (field.get('type') === 'checkboxgroup') {
            name = field.get('name')
                ? field.get('name')
                : this.get('intl').t(form + 's.' + field.get('ext'));
            return name;
        }
        if (form.startsWith('measurement_custom')) {
            form = 'report.measurement_custom1';
        }
        return field.get('name') || this.get('intl').t(form + '.' + field.get('ext'));
    },
    getLogo: function () {
        var d, imageTypes;
        d = new $.Deferred();
        imageTypes = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'];
        this.get('store')
            .findAll('logo')
            .then(function (logo) {
                var img, url;
                if (logo.get('length') > 0 && !Ember.isEmpty(logo)) {
                    logo = logo.get('firstObject.logo');
                    if (logo.length === 0) {
                        url = 'img/brand/logo_with_text.png';
                        return getImageFromUrl(url).then((file) => {
                            return resizeImage(file, 600, 300, 60).then((resized) => {
                                return d.resolve(resized);
                            });
                        });
                    } else {
                        if (logo.length > 0) {
                            logo = logo[0];
                        }
                        if (!imageTypes.includes(logo.type)) {
                            url = 'img/brand/logo_with_text.png';
                            getImageFromUrl(url).then((file) => {
                                return resizeImage(file, 600, 300, 60).then((resized) => {
                                    return d.resolve(resized);
                                });
                            });
                        }
                        img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = function () {
                            var canvas, ctx, dataURL;
                            canvas = document.createElement('CANVAS');
                            ctx = canvas.getContext('2d');
                            canvas.height = this.height;
                            canvas.width = this.width;
                            ctx.drawImage(this, 0, 0);
                            dataURL = canvas.toDataURL();
                            canvas = null;
                            return d.resolve(dataURL);
                        };
                        return (img.src = config.APP.COLLECTOR_API_URL + '/' + logo.url);
                    }
                } else {
                    url = 'img/brand/logo_with_text.png';
                    return getImageFromUrl(url).then((file) => {
                        return resizeImage(file, 600, 300, 60).then((resized) => {
                            return d.resolve(resized);
                        });
                    });
                }
            });
        return d.promise();
    },
    getUserlevel: function (session) {
        var user;
        if (session == null) {
            session = this.get('session');
        }
        user = session.get('currentUser');
        if (user != null) {
            if (typeof user.userlevel === 'string') {
                return parseInt(user.userlevel);
            } else {
                return parseInt(user.get('userlevel'));
            }
        }
    },
    ttapi: function (params) {
        return this.get('ttapiService').request(params.url, params);
    },
    ttapi_url: function () {
        return this.get('ttapiService').host;
    },
    ccapi: function (params) {
        if (config.environment !== 'development' && this.get('flags').test('cc-url')) {
            params.url = this.get('flags').test('cc-url') + '/trigger/' + params.url;
        } else {
            params.url = config.APP.CC_URL + '/' + params.url;
        }
        if (params.data == null) {
            params.data = {};
        }
        if (config.environment === 'development') {
            params.data.capi_url = 'http://capi/';
            params.data.ttapi_url = 'http://ttapi/';
        } else {
            params.data.capi_url = config.APP.COLLECTOR_API_URL + '/';
            params.data.ttapi_url = this.get('ttapiService').host + '/';
        }
        params.data.token = this.get('session.session.authenticated.access_token');
        params.headers = {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.access_token'),
        };
        //console.log "params", params
        return $.ajax(params);
    },
    getApiUrl: function () {
        return config.APP.COLLECTOR_API_URL + '/';
    },
    ajax: function (params) {
        return this.get('capi').request(params.url, params);
    },
    cget: function (form, params = {}, sideload = false) {
        var self;
        self = this;
        if (sideload && !params.sideload) {
            params.sideload = true;
        }
        return new Ember.RSVP.Promise(function (resolve, reject) {
            var paramStr, pluralForm, promise, url;
            pluralForm = self.pluralize(form);
            url = pluralForm;
            //paramArr = []
            //$.each params, (key, value) ->
            //    paramArr.push key + "=" + value
            //paramStr = paramArr.join '&'

            // remove undefined params
            Object.keys(params).forEach(function (key) {
                if (Ember.typeOf(params[key]) === 'undefined') {
                    return delete params[key];
                }
            });
            paramStr = $.param(params);
            if (paramStr !== '') {
                url += '?' + paramStr;
            }
            promise = self.ajax({
                url: url,
                method: 'get',
            });
            promise.then(function (response) {
                var EmberRows, rows;
                if (sideload) {
                    rows = self.injectSideload(pluralForm, response, sideload === 'obj');
                } else if (response[form]) {
                    rows = Em.A(response[form]);
                } else {
                    rows = Em.A(response[pluralForm]);
                }
                EmberRows = Em.A([]);
                rows.forEach(function (row) {
                    return EmberRows.pushObject(Ember.Object.create(row));
                });
                return Ember.run(null, resolve, EmberRows);
            });
            return promise.catch(function (response) {
                response.then = null;
                return Ember.run(null, reject, response);
            });
        });
    },
    injectSideload: function (form, data, asObj = false) {
        var cell,
            column,
            column_name,
            field,
            id,
            item,
            j,
            key,
            l,
            len,
            len1,
            name,
            row,
            rows,
            self,
            str,
            subset,
            subsets;
        self = this;
        rows = data[form];
        subsets = {};
        for (name in data) {
            subset = data[name];
            if (name === form || name === 'count') {
                continue;
            }
            subsets[name] = {};
            for (j = 0, len = subset.length; j < len; j++) {
                item = subset[j];
                if (asObj) {
                    subsets[name][item.id] = item;
                } else {
                    str = '';
                    for (key in item) {
                        cell = item[key];
                        if (key !== 'id' && cell !== null) {
                            str += cell + ' ';
                        }
                    }
                    subsets[name][item.id] = str.substring(0, str.length - 1);
                }
            }
        }
        for (id = l = 0, len1 = rows.length; l < len1; id = ++l) {
            row = rows[id];
            for (column in row) {
                cell = row[column];
                // lets check this fields options.form to match it to sideloaded forms
                if (column !== 'id') {
                    field = self.fieldExists(singularize(form), column)
                        ? self.field(singularize(form), column)
                        : {};
                    if (field.options != null) {
                        column_name = self.pluralize(field.options.form);
                    } else {
                        column_name = self.pluralize(column);
                    }
                } else {
                    column_name = self.pluralize(column);
                }
                if (column_name in subsets) {
                    rows[id][column] = subsets[column_name][cell];
                }
            }
        }
        return rows;
    },
    // used for filter dropdowns. ie. when employer is changed reload user dropdown
    getDropdownDepency: function (field) {
        if (field === 'employer') {
            return [
                {
                    field: 'user',
                    filter: 'employer',
                },
            ];
        } else if (
            field === 'project' &&
            this.testNeedsOne('task') &&
            collector.forms['task'].features != null &&
            collector.forms['task'].features.link === 'project'
        ) {
            return [
                {
                    field: 'task',
                    filter: 'project',
                },
            ];
        } else if (field === 'worktimegroup' && this.testNeedsOne('products.salarytype_v2')) {
            // salarytypesv2 is a special case
            return [
                {
                    field: 'abcensetype',
                    filter: 'worktimegroup',
                },
                {
                    field: 'worktype',
                    filter: 'worktimegroup',
                },
            ];
        }
        return [];
    },
    findFormRelations: function (form, type, params = {}) {
        var pluralForm, self, url;
        self = this;
        pluralForm = pluralize(form).camelize();
        url = pluralForm + '/' + type;
        return DS.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve, reject) {
                return self
                    .ajax({
                        url: url,
                        method: 'GET',
                        data: params,
                    })
                    .then(
                        function (data) {
                            var formattedData;
                            formattedData = self.formatParentFormResponse(data, form, type);
                            return Ember.run(null, resolve, formattedData);
                        },
                        function (jqXHR) {
                            jqXHR.then = null;
                            return Ember.run(null, reject, jqXHR);
                        },
                    );
            }),
        });
    },
    formatParentFormResponse: function (response, form, type) {
        var index, j, key, len, modelName, row, rows, self;
        self = this;
        modelName = collector.getFormObject(form + '.' + type).options.form;
        for (key in response) {
            rows = response[key];
            if (!(key !== 'count')) {
                continue;
            }
            // If model isn't same as gotten type name we have to set response content to right name
            // Like in orientation projects are named ori_sites and fetched from /orinentations/ori_sites
            // but model name is still project and needs to be renamed
            response[modelName] = [];
            // Convert from the {id: id, values: {field1: value, field2: value} format that
            // /:form/:field fetch returns to ember format for pushing to store
            for (index = j = 0, len = rows.length; j < len; index = ++j) {
                row = rows[index];
                row.attributes = row.values;
                delete row.values;
                row.type = modelName;
                response[modelName][index] = row;
            }
            response[modelName] = self.get('store').push({
                data: response[modelName],
            });
        }
        return response[modelName];
    },
    pushToStore: function (response) {
        delete response.count;
        return this.get('store').pushPayload(response);
    },
    pluralize: function (str) {
        return pluralize(str);
    },
    get_greeting: function () {
        var greeting_arr, str, time;
        time = parseInt(moment().format('HH'), 10);
        str = this.get('intl').t('general.welcome');
        if (str == null) {
            return '...';
        }
        greeting_arr = str.split('\n');
        if (time < 2) {
            return greeting_arr[0];
        } else if (time >= 2 && time < 5) {
            return greeting_arr[1];
        } else if (time >= 5 && time < 10) {
            return greeting_arr[2];
        } else if (time >= 10 && time < 12) {
            return greeting_arr[3];
        } else if (time >= 12 && time < 17) {
            return greeting_arr[4];
        } else if (time >= 17 && time < 19) {
            return greeting_arr[5];
        } else if (time >= 19 && time < 22) {
            return greeting_arr[6];
        } else if (time >= 22) {
            return greeting_arr[7];
        }
    },
    parseFormErrors: function (errors) {
        var error, error_name, error_types, j, len, ref, type;
        error_types = [];
        for (j = 0, len = errors.length; j < len; j++) {
            error = errors[j];
            error_name = error.substring(30);
            type = (function () {
                switch (error_name) {
                    case 'calculate_saldo_worktime':
                    case 'remove_saldorow':
                    case 'calculate_new_saldo':
                    case 'update_saldo_group':
                        return 'saldo';
                    default:
                        return false;
                }
            })();
            if (
                type &&
                ((ref = this.get('intl').t('form.errors.type.' + type)),
                indexOf.call(error_types, ref) < 0)
            ) {
                error_types.push(this.get('intl').t('form.errors.type.' + type));
            }
        }
        return error_types;
    },
    // get translation for one column
    getTranslationForColumn: function (form, fieldName, removed = false) {
        var field, intl;
        if (fieldName === 'id') {
            return 'id';
        }
        intl = this.get('intl');
        if (fieldName.substring(0, 9) === 'row_info.') {
            return intl.t('general.' + fieldName);
        }
        if (removed) {
            return this.getBestTranslation(form, fieldName);
        }
        field = this.fields(form)[fieldName];
        if (!field) {
            // Ember.assert("Field '" + fieldName + "' not found for form '" + form + "'");
        }
        if ('name' in field) {
            return field.name;
        } else {
            return this.getBestTranslation(form, fieldName);
        }
    },
    getBestTranslation: function (form, fieldName) {
        var found_key, intl, test_keys;
        intl = this.get('intl');
        test_keys = ['report.' + form + '.' + fieldName, 'defaults.field.' + fieldName];
        found_key = null;
        test_keys.some((key) => {
            if (intl.exists(key)) {
                found_key = key;
                return true;
            }
        });
        if (found_key) {
            return intl.t(found_key);
        } else {
            return test_keys[0];
        }
    },
    customizeField: function (field, custom) {
        var feature, item, k, value;
        for (k in custom) {
            item = custom[k];
            if (typeof item === 'object') {
                for (feature in item) {
                    value = item[feature];
                    if (typeof value === 'object') {
                        // TODO Make better so we can merge unlimited
                        if (!field[k][feature]) {
                            field[k][feature] = value;
                        }
                    } else {
                        field[k][feature] = value;
                    }
                }
            } else {
                field[k] = item;
            }
        }
        return field;
    },

    // Get row linker links
    //   ex. kohdekohtaset tehtävät

    //   id: id of record to fetch links to, ex project record id
    //   form: form name to get the links for, ex "project"
    //   link: name of the link target, ex "task"
    getLinks: function (id, form, link) {
        var params;
        params = {
            url: this.pluralize(form) + '/' + id + '/' + this.pluralize(link),
        };
        return this.ajax(params);
    },

    //   Save row linker links
    //   ex. kohdekohtaset tehtävät

    //   id: id of record to fetch links to, ex project record id
    //   form: form name to get the links for, ex "project"
    //   link: name of the link target, ex "task"
    //   links: array of record ids that should be linked to this id
    saveLinks: function (id, form, link, links) {
        var data, params;
        data = {};
        data[form] = {};
        data[form]['link.' + this.pluralize(link)] = links;
        params = {
            url: this.pluralize(form) + '/' + id + '?validation=off',
            data: JSON.stringify(data),
            type: 'PUT',
        };
        return this.ajax(params);
    },
    canAddWorktime: function (date) {
        var date_interval, interval_arr;
        date_interval = this.field('worktime', 'date')['features']['date_interval'];
        if (date_interval && date_interval === 'locked') {
            //allow today
            if (moment().format('YYYY-MM-DD') === date) {
                return true;
            }
            return false;
        } else if (date_interval) {
            if (typeof date_interval === 'string') {
                interval_arr = date_interval.split(',');
                if (interval_arr.length > 1) {
                    return (
                        moment()
                            .startOf('day')
                            .subtract(interval_arr[0], 'days')
                            .isBefore(moment(date, 'YYYY-MM-DD')) &&
                        moment()
                            .startOf('day')
                            .add(interval_arr[1], 'days')
                            .isAfter(moment(date, 'YYYY-MM-DD'))
                    );
                }
            } else {
                return (
                    moment()
                        .startOf('day')
                        .subtract(date_interval, 'days')
                        .isBefore(moment(date, 'YYYY-MM-DD')) &&
                    moment().startOf('day').add(1, 'days').isAfter(moment(date, 'YYYY-MM-DD'))
                );
            }
        } else {
            return true;
        }
    },
    shortFormat: function (value, length = 2) {
        var j, key, len, split, string, val;
        split = value.split(' ');
        string = '';
        if (split.length > 1) {
            for (key = j = 0, len = split.length; j < len; key = ++j) {
                val = split[key];
                if (key + 1 > length) {
                    continue;
                }
                string += val.slice(0, 1);
            }
        } else {
            string = value.slice(0, length);
        }
        return string.toUpperCase();
    },
    // returns all userlevels in neat array with ids and names
    // input: array skip_levels - skip these levels
    getUserlevels: function (skip_levels) {
        var j, len, lvl, ref, ret, userlevels;
        userlevels = this.field('user', 'userlevel');
        ret = [];
        if (!skip_levels) {
            skip_levels = [];
        }
        ref = userlevels.options;
        for (j = 0, len = ref.length; j < len; j++) {
            lvl = ref[j];
            if (skip_levels.indexOf(parseInt(lvl)) < 0) {
                if (lvl < 9) {
                    ret.push({
                        id: lvl,
                        name: this.get('intl').t('user.userlevel.' + lvl),
                    });
                } else {
                    ret.push({
                        id: lvl.substring(0, 2),
                        name: lvl.substring(2),
                    });
                }
            }
        }
        return ret;
    },
    randomizeColor: function (value) {
        var c, hash, i, j, k, len, string;
        if (value.constructor === Array) {
            string = value.join('');
        } else {
            string = value;
        }
        hash = 0;
        for (i = j = 0, len = string.length; j < len; i = ++j) {
            k = string[i];
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        c = (hash & 0x00ffffff).toString(16).toUpperCase();
        return '#' + '00000'.substring(0, 6 - c.length) + c;
    },
    copyRecord: function (record) {
        var field, form, j, json_record, len, ref;
        form = record.get('constructor.modelName');
        json_record = record.serialize();
        ref = ['status'];
        for (j = 0, len = ref.length; j < len; j++) {
            field = ref[j];
            if (!Ember.isEmpty(json_record[field])) {
                json_record[field] = null;
            }
        }
        record.eachRelationship((key, relationship) => {
            var hasManyArr, ref1;
            if (
                ((ref1 = relationship.kind) === 'belongsTo' || ref1 === 'hasMany') &&
                key !== 'export_salary' &&
                key !== 'export_absence' &&
                key !== 'row_info'
            ) {
                if (this.field(form, key).type === 'databasearray') {
                    hasManyArr = [];
                    record.get(key).forEach((hasManyRecord) => {
                        return hasManyArr.push(this.copyRecord(hasManyRecord));
                    });
                    return Ember.set(json_record, key, hasManyArr);
                } else {
                    return Ember.set(json_record, key, record.get(key));
                }
            }
        });
        return this.get('store').createRecord(form, json_record);
    },
    getSideloads: function (form) {
        var fields, sideloads;
        fields = this.fieldArray(form);
        sideloads = [];
        fields.forEach(function (field) {
            var ref;
            if (
                (ref = field.type) === 'database' ||
                ref === 'databasearray' ||
                ref === 'multiselect'
            ) {
                return field.options.fields.forEach(function (option) {
                    return sideloads.push(field.options.form + '.' + option);
                });
            }
        });
        return sideloads.uniq();
    },
    checkRowValidation: function (form, record, edit_mode = false) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            var data, form_plural, json, json_str, params, ret;
            if (edit_mode) {
                resolve();
                return;
            }
            form_plural = this.pluralize(form);
            json = record.serialize();
            data = {};
            data[form] = json;
            json_str = JSON.stringify(data);
            params = {};
            params.url = form_plural + '?validation=only';
            params.data = json_str;
            params.method = 'POST';
            ret = this.ajax(params);
            return ret.then(
                function (ret) {
                    return resolve(ret);
                },
                function (e) {
                    return reject(e);
                },
            );
        });
    },
});

export default collectorService;
